import React, { useState } from "react";
import { Button } from "react-bootstrap";

const LocationEditorHelpLinks = () => {
  const [showLinks, setShowLinks] = useState(false);

  return (
    <div>
      <Button variant={"link"} onClick={() => setShowLinks((showLinks) => !showLinks)}>
        {`${showLinks ? "Hide Helpful Location Editor Links" : "Helpful Location Editor Links"}`}
      </Button>
      {showLinks && (
        <ul>
          <li>
            <a href="https://dd1psupport.zendesk.com/hc/en-us/articles/32479378872596-Location-Codes">Location Code General Information</a>
          </li>
          <li>
            <a href="https://dd1psupport.zendesk.com/hc/en-us/articles/32479355970068-Editing-Location-Codes">Editing Locations Guide</a>
          </li>
          <li>
            <a href="https://dd1psupport.zendesk.com/hc/en-us/articles/32479387913236-Delivery-Zone-Settings-Widget">Adding Locations Guide</a>
          </li>
          <li>
            <a href="https://dd1psupport.zendesk.com/hc/en-us/articles/32479347975444-Why-Is-My-Menu-Showing-Up-as-Unavailable">
              Menu Not Showing at Certain Locations Troubleshooting
            </a>
          </li>
          <li>
            <a href="https://dd1psupport.zendesk.com/hc/en-us/articles/32479390992532-Multi-Zone-Menus-Fulfilled-at-a-Single-Station">
              Multi-Zone Menus Fulfilled at a Single Station
            </a>
          </li>
        </ul>
      )}
    </div>
  );
};

export default LocationEditorHelpLinks;
