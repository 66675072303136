import { FILESTACK_API_KEY } from "Constants";
import ReactFilestack from "filestack-react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { isFeatureEnabledById } from "util/Utils";

import BulkEditableSetting from "components/owner-app/BulkEditableSetting";
import DismissibleButton from "components/owner-app/dismissable-button/DismissibleButton";
import styled from "styled-components";
import { BbotButton, BbotInput, BbotSelect, BbotToggle, Card } from "top-component-library";

const FILESTACK_IMG_OPTIONS = {
  accept: ["image/svg+xml", "image/png", "image/jpg"],
  fromSources: ["local_file_system", "googledrive", "url"],
  maxFiles: 1,
};

const GeneralTab = (props) => {
  const [settingsState, setSettingsState] = useState({
    locationShortId: "",
    locationName: "",
  });

  const {
    bulkEditMode,
    updateLocationSettings,
    updatedLocationSettings,
    updateCustomMenuProperties,
    removeChanges,
    removeCustomMenuChanges,
    defaultLocationSettings,
    selectedCustomer,
  } = props;

  /**
   * Sets bulkEditVisible variables, which control whether or not the form-inputs are shown when bulk editing
   */
  useEffect(() => {
    const { bulkEditMode } = props;
    setSettingsState({
      ...settingsState,
      runnerNoteBulkEditVisible: !bulkEditMode,
      landingPageNameBulkEditVisible: !bulkEditMode,
      landingPageGroupNameBulkEditVisible: !bulkEditMode,
      zoneForReportsBulkEditVisible: !bulkEditMode,
    });
  }, [props.bulkEditMode]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Loads settingsState, which controls the state of the form-inputs we see on teh page
   */
  useEffect(() => {
    const { defaultLocationSettings } = props;

    setSettingsState({
      ...settingsState,
      isLocationCodeEnabled: defaultLocationSettings.order_allowed === "on",
      locationShortId: defaultLocationSettings.locationShortId || "",
      locationName: defaultLocationSettings.locationName || "",
      externalUrl: defaultLocationSettings.external_url || "",
      landingPageNameEnabled: defaultLocationSettings.landing_page_name?.length > 0 || false,
      landingPageName: defaultLocationSettings.landing_page_name || "",
      landingPageGroupNameEnabled: defaultLocationSettings.landing_page_group_name?.length > 0 || false,
      landingPageGroupName: defaultLocationSettings.landing_page_group_name || "",
      customSwitchLocText: defaultLocationSettings.custom_menu_properties?.switch_text || null,
      allowServiceRequests: defaultLocationSettings.allow_service_requests || false,
      runnerNoteEnabled: defaultLocationSettings.runner_nav_note?.length > 0 || false,
      runnerNote: defaultLocationSettings.runner_nav_note || "",
      kioskModeEnabled: !!defaultLocationSettings.kiosk_enabled,
      stripeTerminalId: defaultLocationSettings.kiosk?.stripe_terminal_id,
      hardwareId: defaultLocationSettings.kiosk?.hardware_id,
      kioskConfigId: defaultLocationSettings.kiosk?.config_id,
      zoneForReportsEnabled:
        (defaultLocationSettings.zone_for_reports && defaultLocationSettings.zone_for_reports !== "No Zone Set") ||
        false,
      zoneForReports:
        defaultLocationSettings.zone_for_reports === "No Zone Set" ? "" : defaultLocationSettings.zone_for_reports,
      menuLogoUrl: defaultLocationSettings.custom_menu_properties?.menu_logo_url,
    });
  }, [props.defaultLocationSettings]); // eslint-disable-line react-hooks/exhaustive-deps

  const isExternalUrlLocation = !!defaultLocationSettings.external_url;

  const bulkEditButtonText = "Edit For Selected Locations";

  return (
    <div>
      <div className={"margin-bottom-2"}>
        <h4>Location Code</h4>
        <p>
          Edit the location code, the landing page name, and more here.
          <a href={"https://dd1psupport.zendesk.com/hc/en-us/articles/32479387298324-Editing-Locations-General"}> More info here.</a>
        </p>
      </div>

      <Card className={"margin-bottom-4"}>
        {!isExternalUrlLocation && (
          <BulkEditableSetting
            title={"Enable Location Code"}
            description={"Toggling this OFF will disable ordering at the selected locations."}
            inBulkEditMode={bulkEditMode}
            onBulkEditInitial={() => updateLocationSettings({ order_allowed: defaultLocationSettings.order_allowed })}
            onBulkEditCancel={() => removeChanges("order_allowed")}
            bulkEditButtonText={bulkEditButtonText}
            toggleConfig={{
              defaultEnabled: settingsState.isLocationCodeEnabled,
              onClick: (e) => {
                setSettingsState({
                  ...settingsState,
                  isLocationCodeEnabled: e.SWITCH_STATE.enabled,
                });
                updateLocationSettings({
                  order_allowed: e.SWITCH_STATE.enabled ? "on" : "off",
                });
              },
            }}
          />
        )}

        {(!bulkEditMode || isExternalUrlLocation) && (
          <>
            <BulkEditableSetting
              title={"Location Code"}
              description={
                "A Location Code represents a specific table, bar seat, area, hotel room, or pickup spot where F&B " +
                "can be delivered to or picked up from.\nIn the case of pickup and delivery orders, you may have " +
                "just one single location code that represents that specific fulfillment method."
              }
              inBulkEditMode={false}
            >
              <BbotInput
                value={settingsState.locationShortId}
                name={"locationShortId"}
                onChange={(e) => {
                  setSettingsState({
                    ...settingsState,
                    locationShortId: e.target.value,
                  });
                  updateLocationSettings({
                    locationShortId: e.target.value,
                  });
                }}
              />
            </BulkEditableSetting>

            <BulkEditableSetting
              title={"Location Long Name"}
              description={"The Location Long Name is the name of the Location as it appears on receipts."}
              inBulkEditMode={false}
            >
              <BbotInput
                value={settingsState.locationName}
                name={"locationName"}
                onChange={(e) => {
                  setSettingsState({
                    ...settingsState,
                    locationName: e.target.value,
                  });
                  updateLocationSettings({
                    locationName: e.target.value,
                  });
                }}
              />
            </BulkEditableSetting>

            {isExternalUrlLocation && (
              <BulkEditableSetting
                title={"External Url"}
                description={"This is the url this location will redirect to."}
                inBulkEditMode={false}
              >
                <BbotInput
                  value={settingsState.externalUrl}
                  name={"locationName"}
                  onChange={(e) => {
                    setSettingsState({
                      ...settingsState,
                      externalUrl: e.target.value,
                    });
                    updateLocationSettings({
                      external_url: e.target.value,
                    });
                  }}
                />
              </BulkEditableSetting>
            )}
          </>
        )}

        {/*only show this field if fulfillment method is runner_with_cc...need to check default settings and update state settings*/}
        {(updatedLocationSettings.properties?.fulfillment_method === "runner_with_cc" ||
          (defaultLocationSettings.fulfillment_method === "runner_with_cc" &&
            !updatedLocationSettings.properties?.fulfillment_method)) && (
          <BulkEditableSetting
            title={"Runner's Note"}
            description={
              "This will allow instructions to be saved for delivering to the selected locations.\n" +
              "The instructions will be shown on the TOP Command Center handheld device.\n" +
              "Note: This setting only applies to the runners with handheld fulfillment methods."
            }
            inBulkEditMode={bulkEditMode}
            bulkEditButtonText={bulkEditButtonText}
            onBulkEditInitial={() =>
              updateLocationSettings({
                runner_nav_note: settingsState.runnerNote,
              })
            }
            onBulkEditCancel={() => removeChanges("runner_nav_note")}
            toggleConfig={{
              toggleShouldBlockContent: true,
              defaultEnabled: defaultLocationSettings.runner_nav_note !== "",
              onClick: (e) => {
                if (!e.SWITCH_STATE.enabled) {
                  setSettingsState({
                    ...settingsState,
                    runnerNote: "",
                  });
                  updateLocationSettings({
                    runner_nav_note: "",
                  });
                }
              },
            }}
          >
            <BbotInput
              value={settingsState.runnerNote}
              onChange={(e) => {
                setSettingsState({
                  ...settingsState,
                  runnerNote: e.target.value,
                });
                updateLocationSettings({
                  runner_nav_note: e.target.value,
                });
              }}
            />
          </BulkEditableSetting>
        )}
      </Card>

      {!isExternalUrlLocation && (
        <>
          <div className={"margin-bottom-2"}>
            <h4>Kiosk Configuration</h4>
            <p>
              Once kiosk mode is turned ON, you can edit the Kiosk Configuration Settings{" "}
              <Link to={"/edit-kiosk-config"}>here</Link>.
            </p>
          </div>

          <Card className={"margin-bottom-4"}>
            <div className={"margin-bottom-2 settings-element"}>
              <div className={"d-flex justify-content-start align-content-center margin-bottom-4"}>
                <span className={"margin-right-4"}>Kiosk Mode</span>
                <DismissibleButton
                  buttonText={"Edit for Selected Locations"}
                  dismissedInitially={!bulkEditMode}
                  onClickCallback={() => {
                    updateLocationSettings({
                      kiosk_enabled: defaultLocationSettings.kiosk_enabled,
                    });
                    setSettingsState({
                      ...settingsState,
                      kioskModeBulkEditVisible: true,
                    });
                  }}
                  onCancelCallback={() => {
                    removeChanges("kiosk_enabled");
                  }}
                >
                  <span>
                    <BbotToggle
                      defaultEnabled={settingsState.kioskModeEnabled}
                      onClick={(e) => {
                        setSettingsState({
                          ...settingsState,
                          kioskModeEnabled: e.SWITCH_STATE.enabled,
                        });
                        updateLocationSettings({
                          kiosk_enabled: e.SWITCH_STATE.enabled,
                        });
                      }}
                    />
                  </span>
                </DismissibleButton>
              </div>

              {!bulkEditMode && settingsState.kioskModeEnabled && (
                <>
                  <div className={"margin-bottom-2"}>
                    <div className="supporting-text margin-bottom-1">
                      Kiosk Hardware ID (Optional, tis is the serial number of the Kiosk Device)
                    </div>
                    <input
                      className={"form-control"}
                      value={settingsState.hardwareId}
                      name={"kioskHardwareId"}
                      onChange={(e) => {
                        setSettingsState({
                          ...settingsState,
                          hardwareId: e.target.value,
                        });
                        updateLocationSettings(
                          {
                            hardware_id: e.target.value,
                          },
                          "kiosk"
                        );
                      }}
                    />
                  </div>

                  {settingsState.stripeTerminalId && (
                    <div>
                      <BbotButton
                        id={"remove-stripe-reader-button"}
                        type={"primary"}
                        danger
                        disabled={!settingsState.stripeTerminalId}
                        onClick={() => {
                          setSettingsState({
                            ...settingsState,
                            stripeTerminalId: "",
                          });
                          updateLocationSettings(
                            {
                              stripe_terminal_id: "",
                            },
                            "kiosk"
                          );
                        }}
                      >
                        Remove Card Reader
                      </BbotButton>
                    </div>
                  )}
                </>
              )}
            </div>
          </Card>
        </>
      )}

      {!isExternalUrlLocation && (
        <>
          <div className={"margin-bottom-2"}>
            <h4>Presentation To Guests/Landing Page</h4>
          </div>

          <Card className={"margin-bottom-4"}>
            <BulkEditableSetting
              title={"Landing Page name"}
              description={
                "This setting applies to certain custom landing pages. This is not used at most restaurants."
              }
              inBulkEditMode={bulkEditMode}
              bulkEditButtonText={bulkEditButtonText}
              onBulkEditInitial={() =>
                updateLocationSettings({
                  landing_page_name: settingsState.landingPageName,
                })
              }
              onBulkEditCancel={() => removeChanges("landing_page_name")}
              toggleConfig={{
                toggleShouldBlockContent: true,
                defaultEnabled: defaultLocationSettings.landing_page_name !== "",
                onClick: (e) => {
                  if (!e.SWITCH_STATE.enabled) {
                    setSettingsState({
                      ...settingsState,
                      landingPageName: "",
                    });
                    updateLocationSettings({
                      landing_page_name: "",
                    });
                  }
                },
              }}
            >
              <BbotInput
                value={settingsState.landingPageName}
                onChange={(e) => {
                  setSettingsState({
                    ...settingsState,
                    landingPageName: e.target.value,
                  });
                  updateLocationSettings({
                    landing_page_name: e.target.value,
                  });
                }}
              />
            </BulkEditableSetting>

            <BulkEditableSetting
              title={"Landing Page Group name"}
              description={
                "Controls how the name of this Location Code displays on your BOS landing page. This is not used at most restaurants."
              }
              inBulkEditMode={bulkEditMode}
              bulkEditButtonText={bulkEditButtonText}
              onBulkEditInitial={() =>
                updateLocationSettings({
                  landing_page_group_name: settingsState.landingPageGroupName,
                })
              }
              onBulkEditCancel={() => removeChanges("landing_page_group_name")}
              toggleConfig={{
                toggleShouldBlockContent: true,
                defaultEnabled: defaultLocationSettings.landing_page_group_name !== "",
                onClick: (e) => {
                  if (!e.SWITCH_STATE.enabled) {
                    setSettingsState({
                      ...settingsState,
                      landingPageGroupName: "",
                    });
                    updateLocationSettings({
                      landing_page_group_name: "",
                    });
                  }
                },
              }}
            >
              <BbotInput
                value={settingsState.landingPageGroupName}
                onChange={(e) => {
                  setSettingsState({
                    ...settingsState,
                    landingPageGroupName: e.target.value,
                  });
                  updateLocationSettings({
                    landing_page_group_name: e.target.value,
                  });
                }}
              />
            </BulkEditableSetting>

            <BulkEditableSetting
              title={"Custom Switch Locations Link"}
              description={""}
              inBulkEditMode={bulkEditMode}
              bulkEditButtonText={bulkEditButtonText}
              onBulkEditInitial={() => {
                updateCustomMenuProperties({ switch_text: "" });
              }}
              onBulkEditCancel={() => {
                removeCustomMenuChanges(["switch_text"]);
              }}
            >
              <div>
                <BbotSelect
                  className={"margin-right-2"}
                  defaultValue={
                    !settingsState.customSwitchLocText
                      ? "default"
                      : settingsState.customSwitchLocText === ""
                      ? "remove"
                      : "custom"
                  }
                  onChange={(value) => {
                    let switchText;
                    if (value === "default") {
                      updateCustomMenuProperties({
                        switch_text: null,
                      });
                      switchText = null;
                    } else if (value === "remove") {
                      updateCustomMenuProperties({
                        switch_text: "",
                      });
                      switchText = null;
                    } else {
                      updateCustomMenuProperties({
                        switch_text: "Switch Locations",
                      });
                      switchText = "Switch Locations";
                    }
                    setSettingsState({
                      ...settingsState,
                      customSwitchLocText: switchText,
                    });
                  }}
                  options={[
                    { label: "Don't use custom link", value: "remove" },
                    { label: "Use custom link", value: "default" },
                    {
                      label: "Use custom text within link",
                      value: "custom",
                    },
                  ]}
                />
                {!!settingsState.customSwitchLocText && (
                  <BbotInput
                    placeholder={"Switch Locations"}
                    value={settingsState.customSwitchLocText || ""}
                    name={"customSwitchLocText"}
                    onChange={(e) => {
                      setSettingsState({
                        ...settingsState,
                        customSwitchLocText: e.target.value,
                      });
                      updateCustomMenuProperties({
                        switch_text: e.target.value,
                      });
                    }}
                  />
                )}
              </div>
            </BulkEditableSetting>

            {isFeatureEnabledById("service_requests", selectedCustomer) && (
              <BulkEditableSetting
                title={"Allow Service Requests"}
                description={
                  "Service Requests are quick requests guests can use to ask for water, napkins, or a takeout box."
                }
                inBulkEditMode={bulkEditMode}
                bulkEditButtonText={bulkEditButtonText}
                onBulkEditInitial={() =>
                  updateLocationSettings({
                    allow_service_requests: settingsState.allowServiceRequests,
                  })
                }
                onBulkEditCancel={() => removeChanges("allow_service_requests")}
                toggleConfig={{
                  defaultEnabled: defaultLocationSettings.allow_service_requests,
                  onClick: (e) => {
                    setSettingsState({
                      ...settingsState,
                      allowServiceRequests: e.SWITCH_STATE.enabled,
                    });
                    updateLocationSettings({
                      allow_service_requests: e.SWITCH_STATE.enabled,
                    });
                  },
                }}
              />
            )}

            <BulkEditableSetting
              title={"Menu Page Logo"}
              description={
                "This logo will be displayed on menu page for the selected location codes. PNG and SVG files are accepted.\n" +
                "We recommend a logo at least 70px wide with a ratio of 1.5:1 or higher."
              }
              inBulkEditMode={bulkEditMode}
              bulkEditButtonText={bulkEditButtonText}
              onBulkEditInitial={() => void 0}
              onBulkEditCancel={() => removeCustomMenuChanges(["menu_logo_url"])}
            >
              <div>
                {settingsState.menuLogoUrl ? (
                  <ImageEditorContainer>
                    <img src={settingsState.menuLogoUrl} alt={"menu-logo"} />
                    <div
                      className={"img-button delete"}
                      onClick={() => {
                        setSettingsState({
                          ...settingsState,
                          menuLogoUrl: "",
                        });
                        updateCustomMenuProperties({ menu_logo_url: "" });
                      }}
                    >
                      <i className={"zmdi zmdi-close"} />
                    </div>
                    <ReactFilestack
                      apikey={FILESTACK_API_KEY}
                      actionOptions={FILESTACK_IMG_OPTIONS}
                      componentDisplayMode={{
                        customText: "Upload Menu Page Logo",
                        customClass: "btn btn-primary margin-x-auto margin-y-1",
                      }}
                      onSuccess={(res) => {
                        setSettingsState({
                          ...settingsState,
                          menuLogoUrl: res.filesUploaded[0].url,
                        });
                        updateCustomMenuProperties({
                          menu_logo_url: res.filesUploaded[0].url,
                        });
                      }}
                      customRender={({ onPick }) => (
                        <div className={"img-button edit"} onClick={onPick}>
                          <i className={"zmdi zmdi-edit"} />
                        </div>
                      )}
                    />
                  </ImageEditorContainer>
                ) : (
                  <ReactFilestack
                    apikey={FILESTACK_API_KEY}
                    actionOptions={FILESTACK_IMG_OPTIONS}
                    componentDisplayMode={{
                      customText: "Upload Menu Page Logo",
                      customClass: "btn btn-primary margin-x-auto margin-y-1",
                    }}
                    onSuccess={(res) => {
                      setSettingsState({
                        ...settingsState,
                        menuLogoUrl: res.filesUploaded[0].url,
                      });
                      updateCustomMenuProperties({
                        menu_logo_url: res.filesUploaded[0].url,
                      });
                    }}
                  />
                )}
              </div>
            </BulkEditableSetting>
          </Card>

          <div className={"margin-bottom-2"}>
            <h4>Reporting</h4>
          </div>

          <Card className={"margin-bottom-4"}>
            <BulkEditableSetting
              title={"Zone For Reports"}
              description={
                <span>
                  Controls the reporting zone for the selected locations. More info can be found{" "}
                  <a href={"https://central.bbot.menu/article/517-editing-locations-reporting"}>here</a>.
                </span>
              }
              inBulkEditMode={bulkEditMode}
              bulkEditButtonText={bulkEditButtonText}
              onBulkEditInitial={() =>
                updateLocationSettings({
                  zone_for_reports: settingsState.zoneForReports,
                })
              }
              onBulkEditCancel={() => removeChanges("zone_for_reports")}
              toggleConfig={{
                toggleShouldBlockContent: true,
                defaultEnabled: defaultLocationSettings.zone_for_reports !== "No Zone Set",
                onClick: (e) => {
                  if (!e.SWITCH_STATE.enabled) {
                    setSettingsState({
                      ...settingsState,
                      zoneForReports: "No Zone Set",
                    });
                    updateLocationSettings({
                      zone_for_reports: "No Zone Set",
                    });
                  }
                },
              }}
            >
              <BbotInput
                value={settingsState.zoneForReports}
                onChange={(e) => {
                  setSettingsState({
                    ...settingsState,
                    zoneForReports: e.target.value,
                  });
                  updateLocationSettings({
                    zone_for_reports: e.target.value,
                  });
                }}
              />
            </BulkEditableSetting>
          </Card>
        </>
      )}
    </div>
  );
};

const ImageEditorContainer = styled.div`
  position: relative;
  max-width: 400px;
  border: 2px solid var(--color-neutral__line);

  img {
    width: 100%;
  }

  .img-button {
    position: absolute;
    right: -10px;
    height: 30px;
    width: 30px;
    padding: 0;
    border-radius: 30px;
    border: 1px solid var(--color-neutral__placeholder);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-neutral__page-background);

    &:hover {
      background-color: var(--color-primary__regular);
      color: white;
    }

    &.edit {
      bottom: -10px;
    }

    &.delete {
      top: -10px;
    }
  }
`;

export default GeneralTab;
