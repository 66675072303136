import axios from "axios";

//Components
import {
  BbotAlert,
  BbotButton,
  BbotModal,
  BbotRichTextInput,
  BbotTable,
  Breadcrumbs,
  Col,
  Input,
  Row,
} from "top-component-library";

import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { generalErrorAlert } from "util/Utils";
import DOMPurify from 'dompurify';

const DEFAULT_HEADER = "We’ve just released a new feature!";
const DEFAULT_BODY =
  "Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid pariatur, ipsum similique veniam.";

const OwnerAnnouncements = (props) => {
  const { userInfo } = props;

  const [headerText, setHeaderText] = useState(DEFAULT_HEADER);
  const [bodyText, setBodyText] = useState(DEFAULT_BODY);
  const [announcements, setAnnouncements] = useState([]);
  const [selectedAnnouncement, setSelectedAnnouncement] = useState(null);

  //react-quill toolbar formatting
  const modules = {
    toolbar: [[{ header: [1, 2, 3, 4, false] }], ["bold", "italic", "underline", "link"], ["clean"]],
  };
  const formats = ["header", "bold", "italic", "underline", "link"];

  //modals
  const [showAnnouncementConfirmModal, setShowAnnouncementConfirmModal] = useState(false);
  const [showClearBannerConfirmModal, setShowClearBannerConfirmModal] = useState(false);
  const [showAnnouncementDetailModal, setShowAnnouncementDetailModal] = useState(false);

  const getAllAnnouncements = async () => {
    try {
      const res = await axios.get("/admin/getAllAnnouncements");
      setAnnouncements(
        res.data.announcements
          .map((announcement, index) => ({
            key: index,
            header: announcement.header,
            body: announcement.body.replace(/<\/?[^>]+(>|$)/g, ""),
            active: DateTime.fromISO(announcement.end_date) >= DateTime.now() ? "Active" : "",
            start_date: DateTime.fromISO(announcement.start_date).toLocaleString(DateTime.DATE_SHORT),
            end_date: DateTime.fromISO(announcement.end_date).toLocaleString(DateTime.DATE_SHORT),
            time_created: DateTime.fromISO(announcement.time_created),
          }))
          .sort((a, b) => (a.time_created < b.time_created ? 1 : a.time_created > b.time_created ? -1 : 0))
      );
    } catch (error) {
      generalErrorAlert(error, "Error Getting Announcements");
    }
  };

  const clearAllActiveAnnouncements = async () => {
    try {
      await axios.post("/admin/clearAllActiveAnnouncements");
      setShowClearBannerConfirmModal(false);
      getAllAnnouncements();
    } catch (error) {
      generalErrorAlert(error, "Error Clearing Active Announcements");
    }
  };

  const publishAnnouncement = async () => {
    const payload = {
      header: DOMPurify.sanitize(headerText, {
        ALLOWED_TAGS: ['b', 'i', 'em', 'strong'],
        ALLOWED_ATTR: []
      }),
      body: DOMPurify.sanitize(bodyText, {
        ALLOWED_TAGS: ['p', 'b', 'i', 'em', 'strong', 'ul', 'ol', 'li', 'a'],
        ALLOWED_ATTR: ['href']
      })
    };

    try {
      await axios.post("/admin/publishAnnouncement?userId=" + userInfo.id, payload);
      setShowAnnouncementConfirmModal(false);
      getAllAnnouncements();
    } catch (error) {
      generalErrorAlert(error, "Error Publishing Announcement");
    }
  };

  useEffect(() => {
    getAllAnnouncements();
  }, []);

  const announcementConfirmModal = () => {
    return (
      <BbotModal
        title={"Publish Announcement to All Owners?"}
        visible={showAnnouncementConfirmModal}
        onCancel={() => setShowAnnouncementConfirmModal(false)}
        onOk={publishAnnouncement}
        okText={"Publish"}
      >
        <div>
          <div>This will the send the announcement to ALL owners.</div>
        </div>
      </BbotModal>
    );
  };

  const clearBannerConfirmModal = () => {
    return (
      <BbotModal
        title={"Clear Announcement Alerts for all owners?"}
        visible={showClearBannerConfirmModal}
        onCancel={() => setShowClearBannerConfirmModal(false)}
        onOk={clearAllActiveAnnouncements}
        okText={"Clear"}
      >
        <div>
          <div>This will the clear ALL announcements for ALL owners.</div>
        </div>
      </BbotModal>
    );
  };

  const announcementDetailModal = () => {
    return (
      <BbotModal
        title={"Announcement Details"}
        visible={showAnnouncementDetailModal}
        onCancel={() => setShowAnnouncementDetailModal(false)}
        footer={null}
      >
        <div>
          <div>
            <div className={"margin-bottom-1"}>
              <b>Header: </b>
              {selectedAnnouncement?.header}
            </div>
            <div className={"margin-bottom-1"}>
              <b>Body: </b>
              {selectedAnnouncement?.body}
            </div>
            <div className={"margin-bottom-1"}>
              <b>Start Date: </b>
              {selectedAnnouncement?.start_date}
            </div>
            <div className={"margin-bottom-1"}>
              <b>End Date: </b>
              {selectedAnnouncement?.end_date}
            </div>
          </div>
        </div>
      </BbotModal>
    );
  };

  return (
    <div>
      <div className={"margin-x-5"}>
        <Breadcrumbs name={"Owner Panel Announcements"} link={"/owner-announcements"} />
        <Row>
          <Col span={24}>
            <div className="margin-bottom-2">
              <h2>Owner Panel Announcements</h2>
            </div>
            <div className={"margin-bottom-4"}>
              <p>Publish an announcement banner that will appear at the top of the Owner Panel for ALL TOP accounts.</p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div className={"margin-bottom-2"}>
              <div className={"margin-bottom-1"}>
                <b>Header:</b>
              </div>
              <Input value={headerText} onChange={(e) => setHeaderText(e.target.value)} />
            </div>
            <div className={"margin-bottom-2"}>
              <div className={"margin-bottom-1"}>
                <b>Body:</b>
              </div>
              <div>
                <BbotRichTextInput value={bodyText} onChange={setBodyText} modules={modules} formats={formats} />
              </div>
            </div>
            <div className={"margin-bottom-2 margin-top-2"}>
              <div className={"margin-bottom-1"}>
                <b>Preview:</b>
              </div>
              <BbotAlert
                message={headerText}
                type="info"
                showIcon
                description={
                  <div
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(bodyText, {
                        ALLOWED_TAGS: ['p', 'b', 'i', 'em', 'strong', 'ul', 'ol', 'li', 'a'],
                        ALLOWED_ATTR: ['href']
                      }),
                    }}
                  />
                }
                closable
              />
            </div>
            <BbotButton
              className="margin-right-2"
              type="primary"
              danger
              onClick={() => setShowClearBannerConfirmModal(true)}
            >
              Clear All Announcements
            </BbotButton>
            <BbotButton type="primary" onClick={() => setShowAnnouncementConfirmModal(true)}>
              Publish Announcement
            </BbotButton>
            <br />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <h4 className={"margin-top-4 margin-bottom-1"}>Announcement History</h4>
            <BbotTable
              id={"announcements-table"}
              columns={["Header", "Active", "Start Date", "End Date"]}
              data={announcements}
              interactive={false}
              pagination={true}
              onRow={(announcement, rowIndex) => ({
                onClick: () => {
                  setSelectedAnnouncement(announcement);
                  setShowAnnouncementDetailModal(true);
                },
              })}
            />
          </Col>
        </Row>
        {announcementConfirmModal()}
        {clearBannerConfirmModal()}
        {announcementDetailModal()}
      </div>
    </div>
  );
};

export default OwnerAnnouncements;
